import { ComponentClass } from 'react';
import { RouteNode } from '../utils/RouteNode';

export const PAGE_MODULE_LIST: PageModuleListItem[] = [
  {
    name: '工作台',
    module: () => import('../pages/dashboard'),
    id: 100000,
  },
  {
    name: '新建工单',
    module: () => import('../pages/work-order-manager/new'),
    id: 100001,
  },
  {
    name: '批量派单',
    module: () => import('../pages/work-order-manager/new-batch'),
    id: 100002,
  },
  {
    name: '服务台工单',
    module: () => import('../pages/work-order-manager/it'),
    id: 100009,
  },
  {
    name: '供应商工单',
    module: () => import('../pages/work-order-manager/supplier'),
    id: 100010,
  },
  {
    name: '工单总览',
    module: () => import('../pages/work-order-manager/all-list'),
    id: 1000101,
  },
  {
    name: 'YUM工单',
    module: () => import('../pages/work-order-manager/yum-list'),
    id: 101015,
  },
  {
    name: '超期及预警',
    module: () => import('../pages/work-order-manager/overdue'),
    id: 100007,
  },
  {
    name: '供应商管理',
    module: () => import('../pages/supplier/supplier'),
    id: 100011,
  },
  {
    name: '服务站管理',
    module: () => import('../pages/supplier/service-station'),
    id: 100012,
  },
  {
    name: '用户与服务站关联',
    module: () => import('../pages/supplier/service-station-store'),
    id: 100013,
  },
  {
    name: '工程师管理',
    module: () => import('../pages/supplier/engineer'),
    id: 100014,
  },
  {
    name: '工程师罚分',
    module: () => import('../pages/supplier/engineer-penalty'),
    id: 100015,
  },
  {
    name: '客户管理',
    module: () => import('../pages/company/company'),
    id: 100016,
  },
  {
    name: '用户管理',
    module: () => import('../pages/company/store'),
    id: 100018,
  },
  {
    name: '系统菜单设置',
    module: () => import('../pages/system/menu-setting'),
    id: 100021,
  },
  {
    name: '知识分类设置',
    module: () => import('../pages/system/knowledege-categroy'),
    id: 100022,
  },
  {
    name: '消息推送设置',
    module: () => import('../pages/system/push-message'),
    id: 100023,
  },
  {
    name: '区域设置',
    module: () => import('../pages/system/area-setting'),
    id: 100024,
  },
  {
    name: 'SLA设置',
    module: () => import('../pages/system/sla-setting'),
    id: 100025,
  },
  {
    name: '工单配置设置',
    module: () => import('../pages/system/support-setting'),
    id: 100026,
  },
  {
    name: '小程序违规清零设置',
    module: () => import('../pages/system/points-reset'),
    id: 100027,
  },
  {
    name: '到达响应时间设置',
    module: () => import('../pages/system/arrive-time'),
    id: 100028,
  },
  {
    name: '繁忙度设置',
    module: () => import('../pages/system/busy-degree'),
    id: 100029,
  },
  {
    name: '账号管理',
    module: () => import('../pages/system/account'),
    id: 100030,
  },
  {
    name: '角色管理',
    module: () => import('../pages/system/role'),
    id: 100031,
  },
  {
    name: '报修分类选项配置',
    module: () => import('../pages/system/config/repair-category'),
    id: 100032,
  },
  {
    name: '认证名称选项配置',
    module: () => import('../pages/system/config/certification-name-setting'),
    id: 100034,
  },
  {
    name: '案件等级选项配置',
    module: () => import('../pages/system/config/case-level-setting'),
    id: 100035,
  },
  {
    name: 'zone选项配置',
    module: () => import('../pages/system/config/zone'),
    id: 100036,
  },
  {
    name: '用户备份历史记录',
    module: () => import('../pages/company/store-backup-list'),
    id: 100040,
  },
  {
    name: '设备品牌设置',
    module: () => import('../pages/system/device-config/brand'),
    id: 100041,
  },
  {
    name: '设备名称设置',
    module: () => import('../pages/system/device-config/name'),
    id: 100042,
  },
  {
    name: '设备型号设置',
    module: () => import('../pages/system/device-config/model'),
    id: 100043,
  },
  {
    name: '设备类型设置',
    module: () => import('../pages/system/device-config/category'),
    id: 100044,
  },
  {
    name: '新店管理',
    module: () => import('../pages/new-store'),
    id: 100045,
  },
  {
    name: '新店配置',
    module: () => import('../pages/system/new-store/setting'),
    id: 100046,
  },
  {
    name: '新店紧急度配置',
    module: () => import('../pages/system/new-store/urgency-setting'),
    id: 100047,
  },
  {
    name: '设备与客户关联',
    module: () => import('../pages/system/device-config/company'),
    id: 100048,
  },
  {
    name: 'kms新建',
    module: () => import('../pages/data-analysis/kms/new'),
    id: 100049,
  },
  {
    name: '用户kms新建',
    module: () => import('../pages/data-analysis/kms/store-new'),
    id: 100050,
  },
  {
    name: 'kms查询',
    module: () => import('../pages/data-analysis/kms/list'),
    id: 100051,
  },
  {
    name: '镜像',
    module: () => import('../pages/data-analysis/mirror'),
    id: 100052,
  },
  {
    name: '用户品牌配置',
    module: () => import('../pages/system/config/store-brand'),
    id: 100053,
  },
  {
    name: '项目通知',
    module: () => import('../pages/data-analysis/project-notification'),
    id: 100054,
  },
  {
    name: '客户重复报修分析',
    module: () =>
      import('../pages/data-analysis/company-action-analysis/repeat-repair'),
    id: 100101,
  },
  {
    name: '客户MA报修排名分析',
    module: () =>
      import(
        '../pages/data-analysis/company-action-analysis/ma-repair-ranking'
      ),
    id: 100102,
  },
  {
    name: '工程师超期分析',
    module: () =>
      import(
        '../pages/data-analysis/engineer-action-analysis/overdue-analysis'
      ),
    id: 100201,
  },
  {
    name: '工程师结单分析',
    module: () =>
      import(
        '../pages/data-analysis/engineer-action-analysis/statement-analysis'
      ),
    id: 100202,
  },
  {
    name: '工程师准时到达分析',
    module: () =>
      import(
        '../pages/data-analysis/engineer-action-analysis/arrival-analysis'
      ),
    id: 100203,
  },
  {
    name: '用户评价分析',
    module: () =>
      import(
        '../pages/data-analysis/engineer-action-analysis/store-evaluation-analysis'
      ),
    id: 100204,
  },
  {
    name: '工程师月报分析',
    module: () =>
      import(
        '../pages/data-analysis/engineer-action-analysis/engineer-monthly-analysis'
      ),
    id: 100205,
  },
  {
    name: '工程师年报分析',
    module: () =>
      import(
        '../pages/data-analysis/engineer-action-analysis/engineer-annual-report-analysis'
      ),
    id: 100206,
  },
  {
    name: '繁忙度报表分析',
    module: () => import('../pages/data-analysis/busyness-analysis'),
    id: 100301,
  },
  {
    name: '设备型号报修排名分析',
    module: () =>
      import(
        '../pages/data-analysis/equipment-analysis/equipment-repair-rate-analysis'
      ),
    id: 100402,
  },
  {
    name: 'SLA报表分析',
    module: () => import('../pages/data-analysis/sla-analysis'),
    id: 100501,
  },
  {
    name: '工程师排名分析',
    module: () =>
      import(
        '../pages/data-analysis/engineer-action-analysis/engineer-ranking-analysis'
      ),
    id: 100601,
  },
  {
    name: '工程师备份记录',
    module: () => import('../pages/supplier/engineer-backup-list'),
    id: 100701,
  },
  {
    name: '工程师罚分备份记录',
    module: () => import('../pages/supplier/engineer-penalty-backup-list'),
    id: 100702,
  },
  {
    name: '服务站备份记录',
    module: () => import('../pages/supplier/service-station-backup-list'),
    id: 100703,
  },
  {
    name: '辅材品牌设置',
    module: () => import('../pages/system/device-assist-config/brand'),
    id: 100801,
  },
  {
    name: '辅材名称设置',
    module: () => import('../pages/system/device-assist-config/name'),
    id: 100802,
  },
  {
    name: '辅材型号设置',
    module: () => import('../pages/system/device-assist-config/model'),
    id: 100803,
  },
  {
    name: '辅材与客户关联',
    module: () => import('../pages/system/device-assist-config/company'),
    id: 100804,
  },
  {
    name: '客户项目通知字典配置',
    module: () => import('../pages/system/config/project-notify-setting'),
    id: 100901,
  },
  {
    name: '辅材申请',
    module: () => import('../pages/new-store/device'),
    id: 100902,
  },
  {
    name: 'SLA核对',
    module: () => import('../pages/data-analysis/sla-check'),
    id: 100903,
  },
  {
    name: 'SLA查看',
    module: () => import('../pages/data-analysis/sla-review'),
    id: 100904,
  },
  {
    name: 'SLA汇总',
    module: () => import('../pages/data-analysis/sla-check-total'),
    id: 100905,
  },
  {
    name: '工单详情',
    module: () => import('../pages/work-order-manager/detail'),
    id: 101001,
  },
  {
    name: '解决方案配置',
    module: () => import('../pages/system/solvePlan'),
    id: 101010,
  },
  {
    name: 'SLA日报表',
    module: () => import('../pages/data-analysis/sla-day'),
    id: 101013,
  },
  {
    name: '工程师罚分配置',
    module: () => import('../pages/system/config/engineer-penalty'),
    id: 101014,
  },
  {
    name: '字典配置',
    module: () => import('../pages/system/dictionary-config'),
    id: 101017,
  },
  {
    name: '帮助与反馈配置',
    module: () => import('../pages/system/config/help-feedback'),
    id: 101018,
  },
  {
    name: '工单字段配置',
    module: () => import('../pages/system/config/work-order-setting'),
    id: 101019,
  },
  {
    name: '服务技能配置',
    module: () => import('../pages/system/config/service-skills'),
    id: 101020,
  },
  {
    name: '工程师审核',
    module: () => import('../pages/supplier/examine-engineer/index'),
    id: 101021,
  },
  {
    name: '工单待审核',
    module: () => import('../pages/work-order-manager/order-examine/index'),
    id: 101023,
  },
  {
    name: '我的工作台',
    module: () => import('../pages/my-work'),
    id: 200000,
  },
  {
    name: '工程师积分列表',
    module: () => import('../pages/supplier/engineer-score'),
    id: 210000,
  },
  {
    name: '报修分析',
    module: () =>
      import('../pages/data-analysis/equipment-analysis/repair-analysis'),
    id: 220000,
  },
  {
    name: '设备型号故障预测',
    module: () =>
      import('../pages/data-analysis/equipment-analysis/equipment-model'),
    id: 230000,
  },
  {
    name: '标准费用配置',
    module: () => import('../pages/system/config/cost-setting'),
    id: 240000,
  },
  {
    name: '费用说明配置',
    module: () => import('../pages/system/config/cost-explain-setting'),
    id: 250000,
  },
  {
    name: '人员管理',
    module: () => import('../pages/company/person-management'),
    id: 260000,
  },
  {
    name: '费用类型配置',
    module: () => import('../pages/system/config/cost-type-setting'),
    id: 270000,
  },
  {
    name: '工程师费用奖惩配置',
    module: () =>
      import('../pages/system/config/cost-rewards-unishments-setting'),
    id: 280000,
  },
  {
    name: '接单统计表',
    module: () => import('../pages/data-analysis/order-receiving-statistics'),
    id: 290000,
  },
  {
    name: '派单等级配置',
    module: () => import('../pages/system/config/level-interval'),
    id: 310000,
  },
  {
    name: '新建工单(驻场版)',
    module: () => import('../pages/work-order-manager/new-resident'),
    id: 320000,
  },
  {
    name: '告警中心',
    module: () => import('../pages/alarm-center/index'),
    id: 330000,
  },
  {
    name: '工程项配置',
    module: () => import('../pages/system/project-item-setting'),
    id: 340000,
  },
  {
    name: '摄像头管理',
    module: () => import('../pages/new-store/camera-list'),
    id: 350000,
  },
  {
    name: '工程类新店列表',
    module: () => import('../pages/new-store/project-order-list'),
    id: 360000,
  },
  {
    name: '邮件通知配置',
    module: () => import('../pages/system/email/email-setting'),
    id: 370000,
  },
  {
    name: '邮件通知模板',
    module: () => import('../pages/system/email/email-template'),
    id: 380000,
  },

  {
    name: 'IT-IMAC费用',
    module: () => import('../pages/cost-management/it-cost/imac'),
    id: 390000,
  },
  {
    name: 'IT-MA费用',
    module: () => import('../pages/cost-management/it-cost/ma'),
    id: 400000,
  },
  {
    name: 'IT-费用报表',
    module: () => import('../pages/cost-management/it-cost/report-form'),
    id: 410000,
  },
  {
    name: '供应商-IMAC费用',
    module: () => import('../pages/cost-management/supplier-cost/imac'),
    id: 420000,
  },
  {
    name: '供应商-MA费用',
    module: () => import('../pages/cost-management/supplier-cost/ma'),
    id: 430000,
  },
  {
    name: '供应商-费用报表',
    module: () => import('../pages/cost-management/supplier-cost/report-form'),
    id: 440000,
  },
  {
    name: '表单管理',
    module: () => import('../pages/system/config/form-manager/form-setting'),
    id: 450000,
  },
  {
    name: '表单定义',
    module: () => import('../pages/system/config/form-manager/form-definition'),
    id: 460000,
  },
  {
    name: '数据报表',
    module: () => import('../pages/data-analysis/work-order-report'),
    id: 470000,
  },
  {
    name: 'IT-新店费用',
    module: () => import('../pages/cost-management/it-cost/new-store'),
    id: 480000,
  },
  {
    name: '供应商-新店费用',
    module: () => import('../pages/cost-management/supplier-cost/new-store'),
    id: 490000,
  },
  {
    name: '审批管理',
    module: () => import('../pages/part-manager/status-list'),
    id: 500000,
  },
  {
    name: '备件查询',
    module: () => import('../pages/part-manager/list'),
    id: 510000,
  },
  {
    name: '备件查询-待返还',
    module: () => import('../pages/part-manager/return'),
    id: 520000,
  },
  {
    name: '超期预警邮件',
    module: () => import('../pages/system/email/email-warning'),
    id: 530000,
  },
  {
    name: '发件邮箱配置(客户)',
    module: () => import('../pages/system/config/company-mail-smtp'),
    id: 540000,
  },
  {
    name: '发件邮箱配置(系统)',
    module: () => import('../pages/system/config/sys-mail-smtp'),
    id: 550000,
  },
  {
    name: '派单组配置',
    module: () => import('../pages/system/config/assign'),
    id: 560000,
  },
  {
    name: '结案类型配置',
    module: () => import('../pages/system/config/close-case-types-setting'),
    id: 570000,
  },
  {
    name: '工单默认通知配置',
    module: () => import('../pages/system/config/order-notice-setting'),
    id: 580000,
  },
];

/**
 * 根据模块id返回路由
 * @param moduleId
 */
export function getModulePageRoute(moduleId: number): RouteNode | undefined {
  const fd = PAGE_MODULE_LIST.find((item) => item.id === moduleId);
  if (fd && fd.routeInfo) {
    return fd.routeInfo;
  }
}

export interface PageModuleListItem {
  name: string;
  module: () => Promise<{ default: ComponentClass<any, any> }>;
  id: number;
  // 动态路由参数
  params?: { [key: string]: string };
  // 路由详情
  routeInfo?: RouteNode;
}
