import React, {Component, Suspense} from "react";
import {ConfigProvider, Layout, Spin} from "antd";
import {Switch} from "react-router-dom";
import config from "./config";
import {connect} from "react-redux"
import {RouteNode} from "./utils/RouteNode";
import {ConnectState} from "./store";
import {updateLanguage} from "./store/reducers/system/actions";
import "./App.less";
import zhCN from "antd/lib/locale/zh_CN";
import {mapRoute} from "./layouts/MapRoute";

class App extends Component<AppProps, AppState>{
  public routes: RouteNode[] = config.routes.map(item => RouteNode.create(item));
  public render(): React.ReactNode {
    return (
      <ConfigProvider locale={zhCN} >
        <Layout className="app">
          <Suspense fallback={<div style={{width: "100vw", height: '100vh', display: "flex", alignItems: "center", justifyContent: "center"}}><Spin /></div>}>
            <Switch>
              {mapRoute(this.routes)}
            </Switch>
          </Suspense>
        </Layout>
      </ConfigProvider>
    )
  }
}



const mapStateToProps = (state: ConnectState) => ({
  language: state.system.language,
  theme: state.system.theme,
})

const mapDispatchToProps = {
  updateLanguage: updateLanguage,
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
interface OwnProps {}
type AppProps = ReturnType<typeof mapStateToProps> & OwnProps;
type AppState = Readonly<{}>
