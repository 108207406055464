import {Action, Dispatch} from "redux";
import {accountExitPc, accountLogin, AccountLoginInfo, personnaleInfo} from "../../../api/system";
import {LoginFormFields} from "../../../pages/user/login/form/LoginForm";
import {SystemActionTypes} from "../../../constants/actions";
import {SystemLanguage} from "../../../constants/SystemLanguage";
import {SystemTheme} from "../../../constants/SystemTheme";
import request from "../../../utils/request";
import requestAt from "../../../utils/requestAt";

/**
 * 更新主题
 * @param theme
 */
export function updateTheme(theme: SystemTheme) {
    return (dispath: Dispatch<UpdateThemeAction>) => {
        dispath({
            type: SystemActionTypes.updateTheme,
            theme,
        })
    }
}

/**
 * 更新语言
 * @param language
 */
export function updateLanguage(language: SystemLanguage) {
    return (dispath: Dispatch<UpdateLanguageAction>) => {
        localStorage.setItem(SystemActionTypes.updateLanguage, language);
        dispath({
            type: SystemActionTypes.updateLanguage,
            language
        })
    }
}

/**
 * 用户登录action
 * @param loginFormFields
 */
export function login(loginFormFields: LoginFormFields, callback?: () => void) {
    return async (dispatch: Dispatch<LoginAction>) => {
        const {status, data} = await accountLogin(loginFormFields.username, loginFormFields.password);
        if (status === 200 && !data.code) {
            const token = data.data + ""
            request.defaults.headers["token"] = token;
            requestAt.defaults.headers["token"] = token;
            const res = await personnaleInfo();
            if (res.status === 200 && !res.data.code && res.data.data) {
                const user = {...res.data.data, token}
                localStorage.setItem(SystemActionTypes.userLogin, JSON.stringify(user));
                dispatch({
                    type: SystemActionTypes.userLogin,
                    user: user,
                });
                if (typeof callback === "function") {
                    callback();
                }
                return user;
            }
        }
    }
}

export function loginOut() {
    return async (dispatch: Dispatch<LoginOutAction>) => {
        await accountExitPc();
        request.defaults.headers["token"] = undefined;
        requestAt.defaults.headers["token"] = undefined;
        dispatch({
            type: SystemActionTypes.userLoginOut
        })
    }
}

export interface UpdateThemeAction extends Action {
    type: SystemActionTypes.updateTheme;
    theme: SystemTheme;
}


export interface UpdateLanguageAction extends Action {
    type: SystemActionTypes.updateLanguage;
    language: SystemLanguage;
}

export interface LoginAction {
    type: SystemActionTypes.userLogin,
    user: AccountLoginInfo;
}

export interface LoginOutAction {
    type: SystemActionTypes.userLoginOut,
}

export type SystemActions = UpdateThemeAction | UpdateLanguageAction | LoginAction | LoginOutAction;