export enum MenuType {
    "Directory" = 1,
    "Page"      = 2,
    "Button"    = 3,
};


export const menuTypes = [
    {
        label: "目录",
        value: MenuType.Directory,
        color: "#2db7f5",
    },
    {
        label: "页面",
        value: MenuType.Page,
        color: "#87d068",
    },
    {
        label: "按钮",
        value: MenuType.Button,
        color: "#f50",
    }
];