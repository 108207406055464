export enum SystemActionTypes {
  updateTheme = 'SYSTEM_UPDATE_THEME_ACTION',
  updateLanguage = 'SYSTEM_UPDATE_LANGUAGE_ACTION',
  userLogin = 'SYSTEM_USER_LOGIN_ACTION_V2',
  userLoginOut = 'SYSTEM_USER_LOGIN_OUT_ACTION',
}

export enum DicdateActionTypes {
  updateAddress = 'DICDATE_UPDATE_ADDRESS',
}

export enum AppActionTypes {
  createStore = 'CREATE_STORE',
  deleteStore = 'DELETE_STORE',
}
