import {MenuSelectListItem} from "../api/menu";
import {NumberLike} from "./request";
import {DeepMenu} from "../interface";

export * from "./random";

/**
 * 递归遍历菜单
 * @param menu
 * @param pid
 */
export function deepMenu(menu: MenuSelectListItem[], pid: NumberLike = 0): DeepMenu[] {
    // 当前项目菜单
    const menus = menu.filter(item => item.pid === pid);
    // 剩余菜单
    const reduceMenus = menu.filter(item => item.pid !== pid);

    return menus.map(item => {
        const children = deepMenu(reduceMenus, item.id);
        return {
            ...item,
            title: item.name,
            key: item.id + "",
            value: item.id + "",
            children,
            isLeaf: children.length === 0,
        }
    }).sort((a, b) => {
        return a.sort - b.sort;
    })
}

/**
 * Create random bytes
 * @param length
 * @author 冉云龙
 */
export function createRandomBytes(length: number): number[] {
    const bytes: number[] = [];
    for (let i = 0; i < length; i++) {
        // create number as range 0-255
        bytes.push(Math.floor(Math.random() * 256))
    }
    return bytes;
}

/**
 * Bytes to hex
 * @param bytes
 * @author 冉云龙
 */
export function byteToHex(bytes: number[]): string {
    return bytes.map(item => {
        const str = item.toString(16);
        if (str.length < 2) {
            return `0${str}`
        }
        return str;
    }).join("");
}

/**
 * 判断是否为JSON数组
 */
export function isJSON(str: string) {
    if (typeof str == 'string') {
        try {
            let obj=JSON.parse(str);
            if(typeof obj == 'object' && obj ){
                return true;
            }else{
                return false;
            }

        } catch(e) {
            return false;
        }
    }
}
