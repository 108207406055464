import {AnyAction, combineReducers, createStore, Middleware, compose, applyMiddleware} from "redux";
import {app, AppState} from "./reducers/app";
import thunkMiddleware from 'redux-thunk';
import reduxLogger from 'redux-logger';
import {system, SystemState} from "./reducers/system";

const middlewares: Middleware[] = [
  thunkMiddleware
];

if (process.env.NODE_ENV === "development") {
  middlewares.push(reduxLogger)
}

export const store = createStore<ConnectState, AnyAction, {}, {}>(
  combineReducers({
    app,
    system,
  }),
  compose(
    applyMiddleware(...middlewares)
  )
)

export interface ConnectState {
  app: AppState;
  system: SystemState;
}
