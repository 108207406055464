import { AnyAction } from 'redux';
import { AppActionTypes } from '../../../constants/actions';

const initState: AppState = {
  user: {},
  store: {},
};

export function app(state: AppState = initState, action: AnyAction) {
  switch (action.type) {
    case AppActionTypes.createStore:
      return { ...state, store: action.store };
    case AppActionTypes.deleteStore:
      return { ...state, store: {} };
    default:
      return { ...state };
  }
}

export interface AppState {
  user: {};
  store: AppStateStoreType;
}
export type AppStateStoreType = Partial<{
  id: number;
  name: string;
  storeNo: string;
  vipStatus: number;
  companyId: number;
}>;
