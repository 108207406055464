import {ComponentClass} from "react";
import {PageModuleListItem} from "./constants/PageModuleList";

const config: ProjectConfig = {
    routes: [
        {
            path: "/admin",
            component: () => import("./layouts/BasicAdminLayout"),
            routes: [
                {
                    path: "/excel-record/:id.html",
                    hideMenu: true,
                    name: "导入记录",
                    component: () => import("./pages/excel-record")
                },
                {
                    path: "/poimarker.html",
                    hideMenu: true,
                    component: () => import("./pages/map"),
                },
                {
                    name: "工单详情",
                    hideMenu: true,
                    path: "/order-detail.html",
                    component: () => import("./pages/work-order-manager/detail")
                },
                {
                    name: "新店工单详情",
                    hideMenu: true,
                    path: "/orderStore-detail.html",
                    component: () => import("./pages/new-store/detailStore")
                },
                {
                    name: "重复报修详情",
                    hideMenu: true,
                    path: "/repair-detail.html",
                    component: () => import("./pages/work-order-manager/detail")
                },
                {
                    path: "/view",
                    name: "预览",
                    hideMenu: true,
                    component: () => import("./layouts/BasicRouterLayout"),
                    routes: [
                        {
                            name: "工单列表",
                            path: "/order.html",
                            component: () => import("./pages/work-order-manager/view")
                        }
                    ]
                },
                {
                    name: "修改工单",
                    hideMenu: true,
                    path: "/edit-order.html",
                    component: () => import("./pages/work-order-manager/edit-order")
                },
                {
                    name: "新店工程类新店",
                    hideMenu: true,
                    path: "/project-order-edit.html",
                    component: () => import("./pages/new-store/project-order-edit")
                },
                {
                    name: "新店工程类新店详情",
                    hideMenu: true,
                    path: "/project-order-detail.html",
                    component: () => import("./pages/new-store/project-order-detail")
                },
                {
                    name: "费用核算详情",
                    hideMenu: true,
                    path: "/cost-detail.html",
                    component: () => import("./pages/cost-management/detail")
                },
                {
                    name: "IT费用统计详情",
                    hideMenu: true,
                    path: "/cost-report-detail.html",
                    component: () => import("./pages/cost-management/it-cost/report-form/detail")
                },
                {
                    name: "供应商费用统计详情",
                    hideMenu: true,
                    path: "/supplier-cost-report-detail.html",
                    component: () => import("./pages/cost-management/supplier-cost/report-form/detail")
                },
                {
                    path: "/from-setting.html",
                    hideMenu: true,
                    name: "表单配置新增",
                    component: () => import("./pages/system/config/form-manager/form-setting/create")
                },
            ]
        },
        {
            path: "/user",
            component: () => import("./layouts/UserLayout"),
            routes: [
                {
                    path: "/login.html",
                    name: "登录",
                    component: () => import("./pages/user/login/Login")
                },
            ]
        },
        {
            name: "大数据",
            hideMenu: true,
            path: "/bigData-map.html",
            component: () => import("./pages/big-data/index")
        },
        {
            path: "*",
            redirect: "/admin",
        }
    ]
}

export interface ProjectConfig {
    routes: RouteConfig[];
}


export interface RouteConfig {
    path: string;
    name?: string;
    icon?: string;
    component?: () => Promise<{ default: ComponentClass<any, any> }>;
    authority?: string[];
    routes?: RouteConfig[];
    redirect?: string;
    hideMenu?: boolean;
    moduleInfo?: PageModuleListItem;
}

export default config;
