import axios, {AxiosError} from "axios";
import {notification} from "antd";
import {SystemActionTypes} from "../constants/actions";
import {store} from "../store";
import qs from 'qs';
import {byteToHex, createRandomBytes} from "./index";

const user = localStorage.getItem(SystemActionTypes.userLogin);
const headers = {
    token: user ? JSON.parse(user).token : undefined,
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
}

export const baseURL = process.env.NODE_ENV === "production" ? "/api" : "/api"

const request = axios.create({
    baseURL: baseURL,
    // timeout: 20000,
    // 格式化表单提交数据
    // 初始化路径查询字符串 过滤掉为空的参数
    paramsSerializer(params) {
        const o: any = {};
        Object.keys(params).forEach(key => {
            if (params[key] !== "" && params[key] !== undefined && !/^_/.test(key)) {
                o[key] = params[key]
            }
        });
        return qs.stringify(o, {
            arrayFormat: 'indices'
        });
    },
    headers,
})

request.interceptors.request.use((config) => {
    if (!/json/.test(config.headers['Content-Type']) && !(config.data instanceof FormData) && typeof config.data === 'object') {
        const o: Record<string, any> = {};
        Object.keys(config.data).forEach(key => {
            if (config.data[key] !== "" && config.data[key] !== undefined && !/^_/.test(key)) {
                o[key] = config.data[key]
            }
        });
        config.data = qs.stringify(o, {
            arrayFormat: 'indices',
            allowDots: true
        })
    }
    // 添加header traceId 生成 10位长度的16进制随机字符串
    config.headers.traceId = byteToHex(createRandomBytes(5));
    if (!config.headers.token) {
        const {user} = store.getState().system;
        if (user && user.token) {
            config.headers.token = user.token;
            request.defaults.headers.token = user.token;
        }
    }
    return config;
}, (error) => Promise.reject(error));

request.interceptors.response.use((response) => {
    const {status, data} = response;
    if (status === 200 && data.code) {
        notification.error({
            message: "系统提示",
            description: data.message
        })

        const err: AxiosError = new Error(data.message) as AxiosError;
        err.isAxiosError = false;
        err.config = response.request;
        err.response = response;
        throw err;
    }
    return response;
}, (err: AxiosError) => {
    if (process.env.NODE_ENV !== "production" && err.isAxiosError && user) {
        if (err.response && err.response.status === 401) {
            notification.error({
                message: `系统提示`,
                description: "登录过期，请重新登录！"
            })
        } else {
            notification.error({
                message: `系统提示`,
                description: err.message
            })
        }
    }

    if (err.response) {
        const {status, data} = err.response as any;

        if (status === 401 && data.code === 7001) {
            store.dispatch({
                type: SystemActionTypes.userLoginOut
            })
        }
    }

    return Promise.reject(err);
})

export default request;

export interface BaseResponse<T> {
    code: number;
    message: number;
    data: T;
}

/**
 * 基础分页查询
 */
export type BaseQueryPageParam<T extends object> = {
    //页码
    pageNo?: number;
    // 页面条数
    pageSize?: number;
    // 排序字段
    sortField?: string;
    // 排序方式
    sortWay?: string;
} & T;

export type BasePageResponse<T> = BaseResponse<{
    /**
     * 数据列表
     */
    dataList: T[];
    /**
     * 页码数据
     */
    pageData: PageData;
}>

export interface PageData {
    /**
     * 总页数
     */
    sumPage: number;
    /**
     * 总条数
     */
    sumNum: number;
    /**
     * 当前页
     */
    pageNo: number;
    /**
     * 每页条数
     */
    pageSize: number;
}

export type NumberLike = string | number;
