import {RouteNode} from "../utils/RouteNode";
import React from "react";
import {Redirect, Route} from "react-router-dom";

export function mapRoute(routes?: RouteNode[]): React.ReactNode {
  if (Array.isArray(routes)) {
    return routes.map(route => {
      if (route.component) {
        const RouteComponent = React.lazy(route.component as any);
        return (
          <Route
            exact={!Array.isArray(route.routes)}
            key={route.path}
            path={route.path}
            component={(props: any) => (
              <RouteComponent
                {...props}
                route={route}
              />
            )}
          />
        );
      } else if (route.redirect) {
        return (
          <Redirect
            exact
            key={route.path}
            path={route.path}
            to={route.redirect}
          />
        )
      }
      return null;
    })
  }
  return null;
}
