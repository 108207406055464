export enum ConfigEnum {
    Qmap = "TGTBZ-X3SRS-QBDO5-6NJHK-AY7DK-MNFJ6",
    apiVersion = "-v100001",
}

export enum ApiUri {
    COMPANY = "/company" + ConfigEnum.apiVersion,
    SYSTEM = "/system" + ConfigEnum.apiVersion,
    SUPPORT = "/support" + ConfigEnum.apiVersion,
    REPORT = "/report" + ConfigEnum.apiVersion,
    STORENEW = "/storenew" + ConfigEnum.apiVersion,
}