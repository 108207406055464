import {SystemActions} from "./actions";
import {AccountLoginInfo} from "../../../api/system";
import {SystemActionTypes} from "../../../constants/actions";
import {SystemTheme} from "../../../constants/SystemTheme";
import {SystemLanguage} from "../../../constants/SystemLanguage";

// 获取本地存储的用户信息
const userInfo = localStorage.getItem(SystemActionTypes.userLogin);
// 获取本地存储的语言配置
const language = localStorage.getItem(SystemActionTypes.updateLanguage) as SystemLanguage;


const initState: SystemState = {
  theme: SystemTheme.dark,
  language: language || SystemLanguage.zhCN,
  user: userInfo ? JSON.parse(userInfo) : null,
};

export function system(state: SystemState = initState, action: SystemActions) {
  switch (action.type) {
    case SystemActionTypes.updateLanguage:
      return {
        ...state,
        language: action.language
      };
    case SystemActionTypes.updateTheme:
      return {
        ...state,
        theme: action.theme,
      }
    case SystemActionTypes.userLogin:
      return {
        ...state,
        user: action.user,
      };
    case SystemActionTypes.userLoginOut:
      localStorage.removeItem(SystemActionTypes.userLogin);
      return  {
        ...state,
        user: null
      }
    default:
      return {...state};
  }
}

export interface SystemState {
  /**
   * 页面颜色主体
   */
  theme: SystemTheme;
  /**
   * 页面语言配置
   */
  language: SystemLanguage;
  /**
   * 用户登录信息
   */
  user: AccountLoginInfo | null;
}