import axios, {AxiosError} from "axios";
import {notification} from "antd";
import {SystemActionTypes} from "../constants/actions";
import {store} from "../store";
import qs from 'qs';

const user = localStorage.getItem(SystemActionTypes.userLogin);
const headers = {
    token: user ? JSON.parse(user).token : undefined,
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
}

export const baseURL = process.env.NODE_ENV === "production" ? "/api-at" : "/api-at"

const requestAt = axios.create({
    baseURL: baseURL,
    paramsSerializer: (params) =>
        qs.stringify(params, {
            allowDots: true,
        }),
    transformRequest: [
        (data, config) => {
            if (config && config['Content-Type'] === 'application/json') {
                return JSON.stringify(data);
            }
            if (
                data instanceof FormData ||
                Object.prototype.toString.call(data) === '[object FormData]' ||
                Object.prototype.toString.call(data) === '[object String]'
            ) {
                return data;
            }
            return qs.stringify(data, {
                allowDots: true,
            });
        },
    ],
    headers,
})

requestAt.interceptors.response.use((response) => {
    const {data} = response;
    if (!(data instanceof ArrayBuffer) && data.code !== '200' && typeof data !== 'string') {
        notification.error({
            message: "系统提示",
            description: data.message
        })

        const err: AxiosError = new Error(data.message) as AxiosError;
        err.isAxiosError = false;
        err.config = response.request;
        err.response = response;
        throw err;
    }
    return response;
}, (err: AxiosError) => {
    if (process.env.NODE_ENV !== "production" && err.isAxiosError && user) {
        if (err.response && err.response.status === 401) {
            notification.error({
                message: `系统提示`,
                description: "登录过期，请重新登录！"
            })
        } else {
            notification.error({
                message: `系统提示`,
                description: err.message
            })
        }
    }

    if (err.response) {
        const {status, data} = err.response as any;

        if (status === 401 && data.code === 7001) {
            store.dispatch({
                type: SystemActionTypes.userLoginOut
            })
        }
    }

    return Promise.reject(err);
})

export default requestAt;

export interface BaseResponse<T> {
    code: number;
    message: number;
    data: T;
}

/**
 * 基础分页查询
 */
export type BaseQueryPageParam<T extends object> = {
    //页码
    pageNum?: number;
    // 页面条数
    pageSize?: number;
    // 排序字段
    sortField?: string;
    // 排序方式
    sortWay?: string;
} & T;

export type BasePageResponse<T> = BaseResponse<{
    /**
     * 数据列表
     */
    list: T[];
    /**
     * 当前页
     */
    pageNum: number;
    /**
     * 每页条数
     */
    pageSize: number;
    /**
     * 总数
     */
    total: number;
}>

export interface PageDatas {
    /**
     * 当前页
     */
    pageNum: number;
    /**
     * 每页条数
     */
    pageSize: number;
    /**
     * 总数
     */
    total: number;
}

export type NumberLike = string | number;
